import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useViewport } from '../../../hooks';

function ShortcutLinks({ dashbaordRights, filteredButtonItems }) {
  const goto = useNavigate();
  let { width } = useViewport();

  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  return (
    <div>
      {dashbaordRights.some((right) => right.value === 28) && (
        <div
          className={`d-flex bg-light ps-3 d-${width < 768 ? 'none' : 'block'}`}
          style={{
            alignItems: 'center',
            padding: '5px',
            borderRadius: '30px',
            marginBottom: '10px',
          }}
        >
          {(filteredButtonItems || []).map(
            ({ tooltip, url, name, icon, className, size }, i) => (
              <div className={`flex mx-1 ${className}`} key={i}>
                <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
                  <i
                    className={`${icon} me-1 cursor-pointer`}
                    style={{ fontSize: size }}
                    onClick={() => goto(`/${url}`)}
                  ></i>
                </OverlayTrigger>
              </div>
            )
          )}

          <i
            className="dripicons-information text-info cursor-pointer"
            style={{ fontSize: '1.2rem', marginTop:'3px' }}
            onClick={toggle}
          />
        </div>
      )}

      <Offcanvas show={show} onHide={toggle} style={{ borderRadius: '0px' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="mb-0">User Guides</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            This shortcut bar contains various links for transaction forms, reports and idenetity forms for easy access
          </div>
          <h5 className="mt-3">Guidelines</h5>
         <ul className="ps-3">
          <li><span className="text-success">Green</span> icons represent <b>Sales Forms and Reports</b> used to create, manage, and view sales-related transactions and summaries.</li>
          <li><span className="text-warning">Yellow</span> icons are for <b>Purchase Forms and Reports</b> used to create, manage, and view  purchase-related transactions and summaries.</li>
          <li><span className="text-primary">Blue</span> icons signify <b>Account Forms and Reports</b> for managing and viewing account-related details and reports.</li>
          <li><span className="text-danger">Red</span> icons indicate <b>Identity Forms</b> used for managing items, customers, suppliers, and accounts forms.</li>
        </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default ShortcutLinks;
